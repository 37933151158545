@font-face {
  font-family: Avenir;
  src: url('../public/fonts/Avenir-Roman.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: Avenir;
  src: url('../public/fonts/Avenir-Medium.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: Avenir;
  src: url('../public/fonts/Avenir-Black.ttf') format('truetype');
  font-weight: 900;
}

body {
  margin: 0;
  font-family: 'Avenir' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Initial spinner  */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid rgb(255, 225, 55);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(255, 225, 55) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

#tillageDate__drop,
#implementation-year-input__drop,
#grazing-season-date-input__drop {
  width: auto !important;
}

#grazing-season-date-input-disabled__drop {
  display: none;
}

.grazing-season-input-box-disabled :nth-child(2) {
  background-color: #f2f2f2;
  color: #808080;
}

#evidence-created-by {
  text-transform: capitalize;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
