.herd {
  background-image: url('../../../../assets/BoundaryTool/infoIcons/herd.svg');
  background-size: cover;
  width: 36px;
  height: 36px;
}

.herd_removed {
  background-image: url('../../../../assets/BoundaryTool/infoIcons/herd-removed.svg');
  background-size: cover;
  width: 36px;
  height: 36px;
}

.structure {
  background-image: url('../../../../assets/BoundaryTool/infoIcons/waterSource.svg');
  background-size: cover;
  width: 36px;
  height: 36px;
}

.event {
  background-image: url('../../../../assets/BoundaryTool/infoIcons/events.svg');
  background-size: cover;
  width: 36px;
  height: 36px;
}
