button.default {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
}

button.primary {
  background-color: #61dafb;
  border: 1px solid #61dafb;
  color: #fff;
}
